<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row >
                    <b-col
                    md="6"
                    class="d-flex justify-content-start"
                    
                    >
                    <h4 class="text-dark font-weight-bold">Filter</h4>
                    
                    </b-col>
                    <b-col
                    md="6"
                    class="d-flex justify-content-end"
                    >
                    <b-button  
                        type="button"
                        class="ml-1"
                        variant="success"
                        @click="generateReport()"
                            >
                        
                            Print
                        </b-button>
                        <b-button  
                        type="button"
                        variant="primary"
                        class="ml-1"
                        @click="ApplyFilter"
                            >
                            
                            Apply
                        </b-button>
                        <b-button  
                        type="button"
                        class="ml-1"
                        variant="danger"
                        @click="clearFilter"
                            >
                        
                            Clear
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="form-boder-input">
                   
                   <b-col md="3">
                       
                       <div class="demo-vertical-spacing ml-1">
                        <b-form-group
                        label="ยา/อุปกรณ์"
                        label-for="keyword"
                        >
                       
      
                        <b-form-input
                            id="service_no"
                            v-model="filterData.keyword"
                            
                        />
                        </b-form-group>
                           </div>
                   
                   </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card >
            <b-row>
                <b-col md="12">
                    <h4 class="text-dark font-weight-bold">Stocks All</h4>
                </b-col>
            </b-row>
           
            <vue-good-table
                    :columns="tableColumns"
                    :rows="items"
                
                    ref="refListTable"
                    :select-options="{
                        enabled: false,
                        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                   
                
                    
                >
                <template
                        slot="table-row"
                        slot-scope="props"
                >
               
                <div v-if="props.column.field === 'product_name'">
                   {{  props.row.title }} 
                   <div>
                   ({{ props.row.sku }})
                   </div>
                   
                </div>
                <div v-else-if="props.column.field === 'balance'">
                  {{ props.row.balance>0?getComma(props.row.balance):0 }}
                   
                </div>
                
                <div v-else-if="props.column.field === 'no'">
                    <span v-if="props.row.service_no">
                        {{  props.row.service_no }}
                    </span>
                    <span v-else-if="props.row.receipt_no">
                        {{  props.row.receipt_no }}
                    </span>
                    <span v-else>-</span>
                </div>
                </template>
                <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                            Items per page
                            </span>
                            <b-form-select
                            v-model="pageLength"
                            :options="['10','20','50']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                        
                        </div>
                        <div>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="Total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            
                            class="mt-1 mb-0"
                            
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
            </vue-good-table>
        </b-card>
        <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        margin="5"
        filename="report.pdf"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        :paginate-elements-by-height="2340"
      
        @hasDownloaded="attemptPrint($event)"
        @beforeDownload="beforeDownload($event)"
        @startPagination="startPagination($event)"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration($event)"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
        >
        <section slot="pdf-content" class="pdf-content text-black">
            <div class="m-2 text-center">
                <b-img
                    src="@/assets/images/logo/viva-logo.png"
                    alt="logo"
                     style="height:45px;"
                     class="mb-1"
                    />
               
                <h5 class=" text-black">รายงานสต๊อก</h5>
               
                <hr />
            </div>
            <section class="pdf-item m-2 text-center "  >
                <table width="100%" style="font-size:10px;" class="text-black" cellpadding="5px" cellspacing="5px">
                    <thead style="margin:0px; padding:0px; border-top:1px solid #000; border-bottom:1px solid #000;">
                        <tr style="margin:0px; padding:0px;">
                           
                          
              
                            <th class="text-left" style="margin:0px; padding:0px;">ยา/อุปกรณ์</th>
                            <th class="text-left" style="margin:0px; padding:0px;">คงเหลือ</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in itemsPrint" :key="'item-'+item.id">
                          
                          
                          <td class="text-left"> {{ item.title }}</td>
                          <td> {{ item.balance>0?getComma(item.balance):0 }}</td>
                          
                      </tr>
                    </tbody>
                </table>
            </section>
        </section>
        </vue-html2pdf>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
///import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'


import { ref,onUnmounted } from '@vue/composition-api'

import { formatDateTh,formatDateToMonthShort,getCommaDecimal,getComma } from '@core/utils/filter'

import vSelect from 'vue-select'
import router from '@/router'
//import receiptStoreModule from '../receiptStoreModule'
import useStocksList from './useStocksList'

//import html2pdf from 'html2pdf.js'

import reportStoreModule from '../reportStoreModule'

import VueHtml2pdf from 'vue-html2pdf'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker,
    VueGoodTable,
    VueHtml2pdf

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    data(){
        return {
            formatDateTh,
            formatDateToMonthShort,
            getComma,
            getCommaDecimal
        }
    },
    setup(props, { emit }){
        const REPORT_STORE_MODULE_NAME = 'app-report';
        if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
        onUnmounted(() => {
            if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
        });
        const {
            filterData,
            refListTable,
            tableColumns,
            items,
            pageLength,
            currentPage,
            Total,
            ApplyFilter,
            clearFilter,
            itemsPrint,
            getPrintData
        } = useStocksList();
        return {
            filterData,
            refListTable,
            tableColumns,
            items,
            pageLength,
            currentPage,
            Total,
            ApplyFilter,
            clearFilter,
            itemsPrint,
            getPrintData
        }
    },
    methods:{
        renderSuggestion(suggestion){
           
            return suggestion.item.first_name+' '+suggestion.item.last_name
        },
         async generateReport () {
            await this.getPrintData();
           
            
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(event){
           // console.log('event', event);
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            options.margin = [0.8, 0.1, 0.8, 0.1];
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).output('bloburl').then(r => { window.open(r) });
        }
    },
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>